/* Loader.css */
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    background-color: rgba(255, 255, 255, 0.8); /* Optional background */
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgba(105, 116, 228, 1.0);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  